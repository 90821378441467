import { DifferentQuantityValue, StateProperties } from "../../stores/calculationStore";

export function createApiPayload(formData: Record<string, string>) {
    return {
        quantity:
        formData[StateProperties.PaperQuantity] === DifferentQuantityValue
            ? formData[StateProperties.PaperQuantityCustom]
            : formData[StateProperties.PaperQuantity],
        print: formData[StateProperties.PaperPrint],
        size: formData[StateProperties.PaperSize],
        type: formData[StateProperties.PaperType],
        folding: formData[StateProperties.PaperFolding],
    };
}