import React, { ReactNode } from 'react';
import { Link } from "gatsby";
import { ProductCategoryModel } from '../../servermodels/productCategoryModel';
import { productCategoryUrl } from '../../services/productCategoryUrl';

type Props = {
    data: ProductCategoryModel,
    className?: string,
    children: ReactNode
}

export const ProductCategoriesMoreLink: React.FC<Props> = (props) => {
    const url = productCategoryUrl(props.data)

    return (
        <Btn url={url} isGlobalUrl={isGlobalUrl(url)} className={props.className} data={props.data}>
            {props.children}
        </Btn>
    );
}

type BtnProps = {
    data: ProductCategoryModel,
    className?: string,
    url: string,
    isGlobalUrl: boolean,
    children: ReactNode,
}

const Btn: React.FC<BtnProps> = (props) => {

    if (props.isGlobalUrl) {
        return (
            <a href={props.url} className={props.className}>{props.children}</a>
        );
    }

    return (
        <Link to={props.url} className={props.className}>{props.children}</Link>
    );
}

function isGlobalUrl(url: string): boolean {
    if (url.startsWith('http:')) {
        return true;
    }

    if (url.startsWith('https:')) {
        return true;
    }

    return false;
}
