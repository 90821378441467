import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";

import Layout from '../components/layout';
import SEO from '../components/seo';
import { sorterByCreatedAt } from '../services/sortUtils';
import { BlogModel } from '../servermodels/blogModel';
import HtmlContent from '../components/htmlcontent';
import { NodeModel } from '../servermodels/nodeModel';
import { ButtonLink } from '../components/buttonLink';


const Blog: React.FC = () => {

    // const [ showGallery, setShowGallery ]= useState(false);
    // const [ imageIndex, setImageIndex ] = useState(0);

    // function handleHideGallery() {
    //     setShowGallery(false);
    // }

    // function handleShowGallery(id: string) {
    //     setImageIndex(parseId(id) - 1);
    //     setShowGallery(true);
    // }
    

    const queryData: NodeModel<BlogModel>[] = useStaticQuery(graphql`
        query NewsQuery {
            allStrapiBlog(sort: {fields: Order, order: DESC}) {
                edges {
                  node {
                    id
                    Order
                    Content {
                      data {
                        Content
                      }
                    }
                    URL
                    Title
                    createdAt
                  }
                }
            }
        }
        `).allStrapiBlog.edges;

    const blogs = queryData.map(d => d.node)

    return (
        <Layout contentIsPage currentPageTitle="Blog">
            <SEO title="Blog" />
            {/* <Gallery show={showGallery} photos={data} onClose={handleHideGallery} startIndex={imageIndex} /> */}
            <div className="container page-news">
                <h1 className="main-title page">
                    <span>Blog</span>
                </h1>
                <div className="col">
                    {blogs.map(n => 
                        <div key={n.id} className="blog-page--item">
                            <h2 className="blog-page--item--title">{n.Title}</h2>
                            <HtmlContent htmlContent={n.Content?.data?.Content} />
                            {n.URL ?
                                <div className="blog-page--item--footer">
                                    <ButtonLink url={n.URL} className="btn--primary">Více →</ButtonLink>
                                </div>
                                : null}
                        </div>)}
                </div>
            </div>
        </Layout>
    )
}

type TitleProps = {
    children: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ children }) => {
    return <h2>{children}</h2>;
}

// function parseId(value: string): number {
//     const match = value.match(/(\d)+/);

//     if (!match) {
//         throw new Error('Missing id in value. Value: ' + value);
//     }

//     return parseInt(match[0]);
// }

export default Blog;