import React from 'react';
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

import { ReferenceModel } from '../../servermodels/referenceModel';

export const MainReferences: React.FC = () => {

    const references: ReferenceModel[] = useStaticQuery(graphql`
        query ReferencesQuery {
            allStrapiReference {
                nodes {
                    id,
                    Title,
                    Order,
                    Logo {
                        alternativeText,
                        caption,
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    `).allStrapiReference.nodes;

    references.sort((a, b) => a.Order - b.Order);

    return (
        <div className="references-container">
            <span>Naše reference</span>
            <ul className="references">
                {references.map(reference => {
                    const r = reference;

                    return (
                        <li key={r.id}>
                            <Link to={'/'}>
                                <img src={r.Logo.localFile.publicURL} title={r.Title} alt={r.Logo.alternativeText} />
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}