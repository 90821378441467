import React from 'react';
import { PageBlockImages, PageBlockModel, PageBlockOfferButton, PageBlockRichText, PageBlockTwoColumnsRichText } from "../servermodels/pageBlockModels";
import HtmlContent from '../components/htmlcontent';
import { getEventSystem } from '../events';
import classNames from 'classnames';

export type GalleryStateObj = {
    imageIndex: number;
}

export function renderPageBlock(
    block: PageBlockModel,
    blockExtraClassName: string | undefined,
    stateObj: GalleryStateObj,
    showGallery: (imgIndex: number) => void
): React.ReactNode {
    switch (block.strapi_component) {
        case 'page-blocks.rich-text': {
            const b = block as PageBlockRichText;
            return <div className={classNames("product-detail-text-block", blockExtraClassName)}>
                <HtmlContent htmlContent={b.Content?.data?.Content} className="wys-content" />
                <div className="clear" />
            </div>;
        }
        case 'page-blocks.two-columns-rich-text': {
            const b = block as PageBlockTwoColumnsRichText;
            return <div className={classNames("product-detail-two-cols row page-content-row", blockExtraClassName)}>
                <div className="col">
                    <HtmlContent htmlContent={b.ContentLeft?.data?.ContentLeft} className="wys-content" />
                </div>
                <div className="col">
                    <HtmlContent htmlContent={b.ContentRight?.data?.ContentRight} className="wys-content" />
                </div>
            </div>;
        }
        case 'page-blocks.offer-button': {
            const b = block as PageBlockOfferButton;

            return (
                <div className={classNames("product-detail-button-block", blockExtraClassName)}>
                    <button onClick={() => getEventSystem().emit('contactClicked')} className="product-detail--contact btn btn--primary">{b.OfferButton}</button>
                </div>
            )
        }
        case 'page-blocks.images': {
            const b = block as PageBlockImages;

            return (
                <div className={classNames("product-detail--section-imgs", blockExtraClassName)}>
                    {b.Images?.map(img => {
                        const imgIndex = stateObj.imageIndex;
                        stateObj.imageIndex++;
                        return (
                            <img key={imgIndex} src={img.localFile.publicURL} alt={img.alternativeText}
                                className={`inner-image ${img.height > img.width ? 'portrait' : 'landscape'}`}
                                onClick={() => showGallery(imgIndex)} />
                        )
                    })}
                </div>
            )
        }
        default:
            throw new Error('Unknown strapi component. Component: ' + block.strapi_component);
    }
}
