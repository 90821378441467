import React from 'react';

type CrossPosition = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

type Props = {
    size: number,
    cross: CrossPosition
}

export const Spd: React.FC<Props> = (props) => {

    const halfSize = props.size / 2;

    const rotate = ROTATION[props.cross];
    const padding = 10;
    const stroke = 3;

    return (
        <svg width={`${props.size}`} height={`${props.size}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g transform={`rotate(${rotate},${halfSize},${halfSize})`}>
                <line x1="0" y1={padding} x2={props.size} y2={padding} strokeWidth={stroke} stroke="var(--secondary)" />
                <line x1={padding} y1="0" x2={padding} y2={props.size} strokeWidth={stroke} stroke="var(--secondary)" />
            </g>
        </svg>
        );
}

const ROTATION: {
    [index: string]: number
} = {
    'topLeft': 0,
    'topRight': 90,
    'bottomLeft': 270,
    'bottomRight': 180,
}