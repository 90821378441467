// IVA libraries
import '@iva/refresher';
import { handleSbrowserSid } from '@iva/sbrowser-sid';
import { startErrorHandling } from '@iva/error-reporter';
import { runLinkdecoration } from '@iva/linkdecoration';

// Modules
import { retargetingDebug as debug } from './modules/debug.js';
import { initConsentLogicInWindow } from './modules/consentManager';
import { retargetingHit, handleRetargetingHits } from './modules/retargetingHit';

// Constants
import { DEBUGGER_STATES } from './modules/constants.js';

startErrorHandling({
  endpoint: 'sklik-ap-static',
  scriptName: 'retargeting.js',
});

debug({ message: 'retargeting.js started', state: DEBUGGER_STATES.START });

window.rc = window.rc || {};
window.rc.retargetingHit = retargetingHit;
delete window.rc.isStub;
initConsentLogicInWindow(debug);

if (!handleSbrowserSid()) {
  runLinkdecoration(null, 30000);
}

// retargeting hits
handleRetargetingHits();

debug({ message: 'retargeting.js finished', state: DEBUGGER_STATES.DONE });
