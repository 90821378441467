import React, { useCallback, useMemo, useState } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";
import { BlogModel } from '../servermodels/blogModel';
import { GalleryStateObj, renderPageBlock } from './renderPageBlock';
import { Gallery, GalleryPhoto } from '../components/gallery';
import { PageBlockImages, PageBlockModel } from '../servermodels/pageBlockModels';
import { Link } from 'gatsby';
import { ButtonLink } from '../components/buttonLink';


type Props = {
    pageContext: {
        blog: BlogModel
    }
};

const BlogPostTemplate: React.FC<Props> = ({ pageContext: { blog } }) => {
    const [showGallery, setShowGallery] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);

    const handleHide = useCallback(() => { setShowGallery(false) }, [false]);
    const handleShow = useCallback((imgIndex: number) => {
        setShowGallery(true);
        setGalleryIndex(imgIndex);
    }, [true]);

    const galleryData = useMemo(() => createGalleryData(blog.PageBlock), [blog.PageBlock]);

    const stateObj: GalleryStateObj = {
        imageIndex: 0,
    }

    return (
        <Layout currentPageTitle={blog.Title} breadcrumbs={[{ title: 'Blog', url: '/blog' }]} contentIsPage>
            <SEO title={blog.Title} description={blog.MetaDescription} keywords={blog.MetaKeywords} />
            <Gallery show={showGallery} photos={galleryData} onClose={handleHide} startIndex={galleryIndex} />
            <div className="container page">
                <h1 className="main-title page">
                    <span>{blog.Title}</span>
                </h1>
                {blog.PageBlock ?
                    blog.PageBlock.map((block, i) => (
                        <React.Fragment key={i}>
                            {renderPageBlock(block, 'blog-detail', stateObj, handleShow)}
                        </React.Fragment>
                    ))
                : null}
                <ButtonLink url="/blog" className="blog-detail--list-btn">Další blogové posty →</ButtonLink>
            </div>
        </Layout>
    )
}

function createGalleryData(pageBlocks: PageBlockModel[]): GalleryPhoto[] {
    if (!pageBlocks) {
        return []
    }

    const result: GalleryPhoto[] = [];

    pageBlocks.filter(p => p.strapi_component === 'page-blocks.images')
        .forEach(p => {
            const b = p as PageBlockImages;
            b.Images?.forEach(i => {
                result.push({
                    original: i.localFile.publicURL,
                    thumbnail: i.localFile.publicURL,
                    originalTitle: i.caption,
                    description: i.caption,
                });
            });
        });

    return result;
}

export default BlogPostTemplate;