import React, { ReactNode } from 'react';

import { Spd } from './spd';

type Props = {
    children: ReactNode
}

export const SpdContainer: React.FC<Props> = (props) => {

    const spdSize = 40;

    const gridTemplate = `${spdSize}px auto ${spdSize}px`;

    return (
        <div className="spd" style={{ gridTemplateColumns: gridTemplate, gridTemplateRows: gridTemplate }}>
            <Spd size={spdSize} cross="topLeft" />
            <span></span>
            <Spd size={spdSize} cross="topRight" />
            <span></span>
            {props.children}
            <span></span>
            <Spd size={spdSize} cross="bottomLeft" />
            <span></span>
            <Spd size={spdSize} cross="bottomRight" />
        </div>
        );
}