import React, { FC } from "react";
import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";
import { CalculationBtn } from "./calculationBtn";
import { calculationStore, StateProperties } from "../../stores/calculationStore";

const InputName = StateProperties.PaperFolding;

const BUTTONS = [
    { value: 'no-folding', label: "bez skládání", desc: "", imgSrc: "/bez_prelozeni.svg", imgAlt: "Bez přeložení" },
    { value: 'simple-folding', label: "napůl", desc: "(jednoduchý lom)", imgSrc: "/napul.svg", imgAlt: "Jednoduchý lom" },
    { value: 'window-folding', label: "okénkový lom", desc: "", imgSrc: "/okenkovy.svg", imgAlt: "Okénkový lom" },
    { value: 'z-folding', label: "na třetiny do Z", desc: "(harmonikový lom)", imgSrc: "/harmonikovy.svg", imgAlt: "Harmonikový lom" },
    { value: 'c-folding', label: "na třetiny do C", desc: "(zavinutý lom)", imgSrc: "/zavinuty.svg", imgAlt: "Zavinutý lom" },
];

export const CalculationPaperFolding: FC = () => {
    const { checked, setProperty } = useStore(
        calculationStore,
        useShallow(s => ({
          checked: s.state[InputName],
          setProperty: s.setProperty,
        }))
    )
    
    const handleChange = (value: string) => {
        setProperty(InputName, value)
    }

    return (
        <div className="section-items paper-folding">
            {BUTTONS.map(({ value, label, desc, imgSrc, imgAlt }) => (
                <CalculationBtn
                    key={`${InputName}-${value}`}
                    id={`${InputName}-${value}`}
                    name={InputName}
                    value={value}
                    isActive={value === checked}
                    onChange={handleChange}
                    className="btn-radio-simple-item"
                >
                    <div className="title">
                        <span className="label">{label}</span>
                        <span className="description">{desc}</span>
                    </div>
                    <img src={imgSrc} alt={imgAlt} />
                </CalculationBtn>
            ))}
        </div>
    )
}
