import { useMemo, useState } from "react"

type Result = {
  isProcessing: boolean
  demandId: string
  success: boolean
  errorMessage: string
  upload: (
    url: string,
    files: { id: string; file: File }[],
    data: object
  ) => void
}

type ProcessingState = {
  isProcessing: boolean
  demandId: string
  success: boolean
  errorMessage: string
}

export const useCalculationPriceRequest = (): Result => {
  const [processing, setProcessing] = useState<ProcessingState>({
    isProcessing: false,
    success: false,
    demandId: '',
    errorMessage: "",
  })

  const result = useMemo(
    () => ({
      ...processing,
      upload: (
        url: string,
        files: { id: string; file: File }[],
        data: object
      ) => {
        const formData = new FormData()
        files.forEach(f => {
          formData.append(`files.${f.id}`, f.file)
        })
        formData.append("data", JSON.stringify(data)) // strapi needs some data object

        setProcessing({ isProcessing: true, success: false, demandId: "", errorMessage: "" })

        fetch(url, {
          method: "POST",
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              setProcessing({
                isProcessing: false,
                success: false,
                demandId: "",
                errorMessage: "Chyba odpovědi serveru",
              })
              throw new Error("Wrong status code: " + response.status)
            }
            return response.json()
          })
          .then(data => {
            setProcessing({
              isProcessing: false,
              success: true,
              demandId: data.demandId,
              errorMessage: "",
            })
          })
          .catch(error => {
            setProcessing({
              isProcessing: false,
              success: false,
              demandId: "",
              errorMessage: "Chyba při komunikaci se serverem",
            })
            console.error("Send demand error", error)
          })
      },
    }),
    [processing]
  )

  return result
}
