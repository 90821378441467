import React, { FC, ReactNode, forwardRef } from "react"

type Props = {
  title: ReactNode
  children: ReactNode
  hint?: ReactNode
}

export const CalculationSection = forwardRef<HTMLDivElement, Props>(
  ({ title, children, hint }, ref) => {
    return (
      <div ref={ref} className="section">
        <h2>{title}</h2> {hint && <p className="hint">{hint}</p>}
        <div>{children}</div>
      </div>
    )
  }
)
