import React, { FC, ReactNode } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

type Props = {
    url: string,
    children: ReactNode,
    className?: string,
    target?: '_blank',
}

export const ButtonLink: FC<Props> = ({ url, children, className, target }) => {
    const global = isGlobalUrl(url);
    const cls = classNames('btn', className);

    if (global) {
        return (
            <a href={url} className={cls} target={target}>{children}</a>
        );
    }

    return (
        <Link to={url} className={cls} target={target}>{children}</Link>
    );
}

function isGlobalUrl(url: string): boolean {
    if (!url) {
        return false;
    }

    if (url.startsWith('http:')) {
        return true;
    }

    if (url.startsWith('https:')) {
        return true;
    }

    return false;
}
