import React, { FC, ReactNode, useEffect } from 'react'
import { DialogService } from '../services/dialog'

type Props = {
  id: string
  show: boolean
  children: ReactNode
  className?: string
  onClose: () => void
}

export const Modal: FC<Props> = ({ id, show, children, className, onClose }) => {
  useEffect(() => {
    if (show) {
      DialogService.show(id)
    }
    else {
      DialogService.hide(id)
    }
  }, [show])

  const cls = `modal ${className}`

  return (
    <div id={id} className={cls} tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}