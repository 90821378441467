/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({location, prevLocation}) => {
    fixAnchors();
}

exports.onInitialClientRender = () => {
    if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        var script = document.createElement('script');
        script.src = '/smartsupp.js';
        script.setAttribute('defer', 'true');
        document.body.appendChild(script);
    }
}

function fixAnchors() {
    var anchors = document.querySelectorAll('a');
    anchors.forEach(a => {
        var href = a.getAttribute('href');
        if (href && href.startsWith('#')) {
            var el = document.querySelector(href);
            if (el) {
                var bound = el.getBoundingClientRect();
                a.addEventListener('click', (e) => {
                    e.preventDefault();

                    window.scrollTo(0, bound.top - 80);
                });
            }
        }
    });
}