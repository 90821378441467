import React, { FC } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LeafletsCalculation } from "../components/calculation/leafletsCalculation";

const LetakyKalkulace: FC = () => {
  return (
    <Layout contentIsPage currentPageTitle="Letáky kalkulace">
      <SEO title="Letáky kalkulace" />
      <LeafletsCalculation />
    </Layout>
  )
}

export default LetakyKalkulace
