import React from 'react';

type Props = {
    htmlContent: string,
    className?: string
}

function createMarkup(content: string) {

    return {__html: content};
}

const HtmlContent: React.FC<Props> = (props) => {

    return (
        <div className={props.className} dangerouslySetInnerHTML={createMarkup(props.htmlContent)}>
        </div>
        );
}

export default HtmlContent;