import React, { FC } from "react"

type Props = {
  demandId: string
}

export const CalculationSentThanks: FC<Props> = ({ demandId }) => {
  return (
    <div className="sent-thanks">
      <p>&nbsp;</p>
      <p><strong>Děkujeme za Vaši objednávku!</strong></p>
      <p>Vaše objednávka byla úspěšně přijata a nyní ji zpracováváme.</p>
      <p>&nbsp;</p>
      <p><strong>Číslo objednávky: {demandId}</strong></p>
      <p>&nbsp;</p>
      <p><strong>Dodací lhůta:</strong> Očekávaná doba zhotovení je 3–5 pracovních dnů.</p>
      <p>Pokud máte jakékoliv dotazy nebo potřebujete pomoc s Vaší objednávkou, neváhejte nás kontaktovat na e-mailu <a href="mailto:brko@tiskarnabrko.cz">brko@tiskarnabrko.cz</a> nebo na telefonu&nbsp;<a href="tel:+420 545 216 490">+420 545 216 490</a>.</p>
      <p>&nbsp;</p>
      <p><strong>Další kroky:</strong></p>
      <p>
        <ul>
          <li>Budete informováni e-mailem, jakmile Vaše objednávka bude hotová.</li>
          <li>V případě jakýchkoli změn nebo problémů s objednávkou Vás budeme neprodleně kontaktovat.</li>
        </ul>
      </p>
      <p>&nbsp;</p>
      <p><strong>Děkujeme za Vaši objednávku!</strong></p>
      <p>S pozdravem,</p>
      <p>Tým tiskárny BRKO</p>
    </div>
  )
}
